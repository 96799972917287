//Bootstrap 
import * as bootstrap from 'bootstrap';
import Alert from 'bootstrap/js/dist/alert';
// or, specify which plugins you need:
//import { Tooltip, Toast, Popover } from 'bootstrap'

//jquery
import $ from 'jquery';
import jquery from 'jquery';

// Gsap components
import gsap from "gsap";
import { Timeline } from 'gsap/gsap-core';
import { TweenMax, TimelineMax, timeline, from } from "gsap"; // Also works with TweenLite and TimelineLite
import Flip from "gsap/Flip";
// get other plugins:
//import ScrollTrigger from "gsap/ScrollTrigger";
//import Draggable from "gsap/Draggable";
import Flip from "gsap/Flip";

// ScrollMagic components
import * as ScrollMagic from 'scrollmagic'; 
//import 'scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators';
import { ScrollMagicPluginGsap } from 'scrollmagic-plugin-gsap';

  ScrollMagicPluginGsap(ScrollMagic, TweenMax, TimelineMax);

  // init controller
  var controller = new ScrollMagic.Controller();


//////////////////////////////
// PINING
/////////////////////////////

$(function () { // wait for document ready

  var sceneStart = new ScrollMagic.Scene({triggerElement: "#trigger1", triggerHook: 0, duration: "200"})
      .setPin("#start")
      .addIndicators({name: "Intro"}) // add indicators (requires plugin)
      .addTo(controller);
      
  // build scene in to the cave
  var sceneCave = new ScrollMagic.Scene({triggerElement: "#section-three", triggerHook: 0, duration: "500"})
  .setPin("#section-three")
  .addIndicators({name: "Cave"}) // add indicators (requires plugin)
  .addTo(controller);

});


$('#start').each(function() {
  // Logo INTRO ///

  var tlPartners = new Timeline()
    .from("#start", {duration:3, opacity:0, scale: 1.2})  //child tweens will inherit the duration and from the parent timeline!
    .from(".presenta", {opacity:0, duration:1}, '>')
    .from("#logo", {xPercent:-120})
    .from(".copyright", {xPercent:-120}, '#logo')
    .fromTo(".scroll-to", {opacity: 0}, {opacity: 1, duration: 1}, '#logo');

// Sky INTRO
/*
const clouds = document.querySelectorAll(".cloud");

for (let i = 0; i < clouds.length; i++) {
  let data = clouds[i].getBBox();
  gsap.fromTo(
      clouds[i],
      { x: 1702.41 - data.x },
      {
        duration: gsap.utils.random(8, 12),
        x: 0 - data.x - data.width,
        repeat: -1,
        ease: "none"
      }
    )
    .progress(Math.random());
}

gsap.to("svg", 1, { opacity: 1, ease: "none"});

*/

  /// Alice bored animation ///

    // bored arm
    var boredArm = new TimelineMax({repeat: -1, yoyo: true })
        .add(TweenMax.to(".arm", 0.3, {bottom: "+=5", rotation: -3, transformOrigin: '50% 0%'}))
        .add(TweenMax.to(".arm", 0.3, {bottom: "-=10", rotation: 0, transformOrigin: '50% 0%'}))
        .add(TweenMax.to(".arm", 0.3, {bottom: "+=5", rotation: 3, transformOrigin: '50% 0%'}))
        .add(TweenMax.to(".arm", 0.3, {bottom: "-=5", rotation: 1.5, transformOrigin: '50% 0%'}))
        .add(TweenMax.to(".arm", 0.3, {bottom: "+=5", rotation: -1.5, transformOrigin: '50% 0%'}))
        .add(TweenMax.to(".arm", 0.3, {bottom: "+=5", rotation: 0, transformOrigin: '50% 0%'}))
        .add(TweenMax.to(".arm", 0.3, {bottom: "-=10", rotation: 1.5, transformOrigin: '50% 0%'}));
    
    // arm movement
    new ScrollMagic.Scene({
            duration: '100%'
            //offset: -100
        })
        .setTween(boredArm)
        .addTo(controller);

  // bored leg
    var boredLeg = new TimelineMax({repeat: -1, yoyo: true})
        .add(TweenMax.to(".leg", 1, {rotation: -7, transformOrigin: '0% 65%'}))
        .add(TweenMax.to(".leg", 1, {rotation: 3, transformOrigin: '0% 65%'}));
    
    // leg movement
    new ScrollMagic.Scene({
            duration: '100%'
            //offset: -100
        })
        .setTween(boredLeg)
        .addTo(controller);
  
  
  /// alice liying

  var tween = TweenMax.fromTo( '#alice-river', { x: 0, y: 0, scale:0.8 }, { x: 0, y: 0, scale:1.3, transformOrigin: "100% 0" });

  var scene = new ScrollMagic.Scene({
    triggerElement: '#start', 
    duration: '100%',
    triggerHook: 0.25
    })
    .setTween(tween)
    .addTo(controller);
  
    /// alice liying

  var tweenTree = TweenMax.fromTo( '#tree-river', { x: 50, y: 0, scale:0.8 }, { x: -200 , y: 0, scale:1.3 });

  var scene = new ScrollMagic.Scene({
    triggerElement: '#start', 
    duration: '100%',
    triggerHook: 0.25
    })
    .setTween(tweenTree)
    .addTo(controller);

  /// The rabbit run run 
  var tweenRabbit = TweenMax
    .fromTo( '#rabbit-river',{ x: 1920, y: -50, scale:1 }, { x: -1100 , y: -50, scale:1});

  var sceneRabbit = new ScrollMagic.Scene({
    triggerElement: '#start', 
    duration: '100%',
    triggerHook: 0.25,
    offset: 50
    })
    .setTween(tweenRabbit)
    .addTo(controller);


    //intro logo

      var tlIntroLogo = new Timeline()
        .from("#start .logo-a1", {opacity:0, x:10, delay: .5},)
        .from("#start .logo-a2", {opacity:0, x:-10}, '<')
        .from("#start .logo-l", {opacity:0, x:10}, '>')
        .from("#start .logo-i2", {opacity:0, x:-10}, '<')
        .from("#start .logo-i1", {opacity:0, x:10}, '>')
        .from("#start .logo-c", {opacity:0, x:-10}, '<')
        .to("#start #logo-alicia", {scale:.9, y:-20})
        .from("#start #logo-line", {opacity:0})
        .from("#start #tagline", {duration:1, opacity:0, y:20})
        //.from("#start .btn", {opacity:0, x:-10})
        //.to("#homeBkgd", {opacity: 0.7, duration: 1})
        //.from("#footer span", {duration:1, opacity:0, y:12});
      
        var IntroLogo = new ScrollMagic.Scene({
          triggerElement: '#start',
          //triggerHook: "0.5",
          reverse: false
          })
          .setTween(tlIntroLogo)
          .addIndicators({
            name: 'SECTION END',
            colorTrigger: 'red',
            colorStart: 'red',
            colorEnd: 'rer'
          })
          .addTo(controller);
      

});

  ///////////////////////////////////
  // ALice & Rabbit go to the cave
  ///////////////////////////////////
  $('#section-three').each(function() {

  var tlHole = new Timeline()
    //tween rabbit
    .fromTo("#rabbit-hole-1", {x: 0, opacity:0}, {duration: 1, x: -200, opacity:1}, "#section-three")   
        .to("#rabbit-hole-1", {duration: 1, opacity: 0})
    .fromTo("#rabbit-hole-2", {opacity: 0}, {duration: 1, opacity:1}, "<")
        .to("#rabbit-hole-2", {duration: 1, opacity: 0})
    //tween Alice
    .fromTo("#alice-hole-1", {x: 0, opacity:0}, {duration: 1, opacity:1}, "#rabbit-hole-2")   
        .to("#alice-hole-1", {duration: 1, opacity: 0})
    .fromTo("#alice-hole-2", {opacity: 0}, {duration: 1, x: -10, opacity:1}, "<")
        .to("#alice-hole-2", {duration: 1, opacity: 0});

  //ar sceneHole = new ScrollMagic.Scene({
  var tlHole = new ScrollMagic.Scene({
   triggerElement: '#section-three',
   triggerHook: "0.25",
   duration: '500'
   //reverse: false
  })
  .setTween(tlHole)
  .addTo(controller);

});

///////////////////
// ALice fall out the hole 1
///////////////////
/*
$('.aliceCae1').each(function() {
  var tl3 = new TimelineMax();
  var child = $(this).find('.child');
  var child2 = $(this).find('.child2');
  var child3 = $(this).find('.child3');
    tl3.to(child, 1, { y: -80, rotation:46, duration:0.2, scale: 0.5, ease: 'Linear.easeNone' });
    tl3.to(child2, 1, { y: 180, rotation:-16, scale: 0.7, ease: 'Linear.easeNone' }, "<");
    tl3.to(child3, 1, { y: 60, rotation:90, duration:0.2, ease: 'Linear.easeNone' });
    

  var scene = new ScrollMagic.Scene({
    triggerElement: this,
    triggerHook: 0.9,
    duration: '200%'
  })
    .setTween(tl3)
    .addIndicators({
      name: 'Alicia Cae',
      colorTrigger: 'YELLOW',
      colorStart: 'yellow',
      colorEnd: 'yellow',
      indent: 10
    })
    .addTo(controller);
});

*/

//////////////////////////////
// ALice fall out the hole 2
/////////////////////////////

/*
var sceneAliceCae = new ScrollMagic.Scene({triggerElement: "#triggerBluehole", triggerHook: 0, duration: "200%"})
  .setPin("#section-five")
  .addIndicators({name: "PIN FIVE"}) // add indicators (requires plugin)
  .addTo(controller);
  */


$('#section-five').each(function() {

  var tl4 = new TimelineMax();
  var poso = $(this).find('#hole-in');
  var alicia = $(this).find('#alice-cae');
  var ways = $(this).find('#ways');
  var bluehole = $(this).find('#blueHole');
  var circle = $(this).find('#circleText');
    
    tl4.set(poso, {y: 50, x: -50, rotation: 30, transformOrigin: '35% 33%'});
    tl4.to(poso, 40, {rotation: -10, scale: 0.7, ease: 'Linear.easeNone' });
    tl4.fromTo(bluehole, 30, { y: 0, scale: 1 }, { y: 0, scale: 1, ease: 'Linear.easeNone' }, "<"); // zoom fondo blue
    tl4.to(poso, 30, {rotation: -55, scale: 0.2, ease: 'Linear.easeNone' });
    tl4.fromTo(alicia, 40, {y: 300, x: 150, rotation: 0, scale: 0.2}, { y: 600, x: 350, rotation: -230, scale: 2, ease: 'Linear.easeNone' }, "poso-=75%"); 
    tl4.to(alicia, 60, { y: 1050, x: 700, scale: 2.5, opacity: 0, rotate: -350, ease: 'Linear.easeNone' }); // ssale alicia
    tl4.to(poso, 30, {scale: 0, ease: 'Linear.easeNone' }, "<"); // fade out poso
    tl4.to(bluehole, 30, { y: -400, scale: 1, ease: 'Linear.easeNone' }, "<"); // zoom fondo blue
    tl4.fromTo(ways, 70, {y: 400, x: 0, rotation: 0, opacity: 0, scale: 0.3, transformOrigin: '50% 50%'}, { y: 750, x: 150, rotation: 360, opacity: 1, scale: 1.7, transformOrigin: '50% 50%', ease: 'Linear.easeNone' }, "alicia-=75%");
    tl4.fromTo(circle, 35, {y: 900, x: 340, rotation: 0, opacity: 0, scale: 0.3, transformOrigin: '50% 50%'}, { y: 950, x: 340, rotation: 360, opacity: 1, scale: 1, transformOrigin: '50% 50%', ease: 'Linear.easeNone' }, "ways-=50%");

  var scene4 = new ScrollMagic.Scene({
    triggerElement: '#section-five',
    triggerHook: 0.7,
    duration: '170%'
  })
    .setTween(tl4)
    .addTo(controller);

});




//////////////////////////////
// ALice fall que camino debo tomar
/////////////////////////////
/*
$('.aliceCae3').each(function() {
  
  var tl5 = new TimelineMax();
  var ways = $(this).find('.child');
  var alicia = $(this).find('.child2');
    tl5.to(ways, 1, { y: 180, rotation:180, scale:3, ease: 'Linear.easeNone' });
    tl5.fromTo(alicia, 1, {y: -400, rotation:0, scale:0},  { y: 600, rotation:380, scale:4,  ease: 'Linear.easeNone' }, "<");

  var scene3 = new ScrollMagic.Scene({
    triggerElement: this,
    triggerHook: 0.9,
    duration: '200%'
  })
    .setTween(tl5)
    .addTo(controller);
});

*/

///////////////////////////////////
// Gato de Chesire
///////////////////////////////////

var sceneCat = new ScrollMagic.Scene({triggerElement: "#triggerCat", triggerHook: 0, duration: "700"})
  .setPin("#section-vignetta")
  .addIndicators({name: "Cat (duration: 700)"}) // add indicators (requires plugin)
  .addTo(controller);

$('.vignetta').each(function() { 

  var tlCat = new Timeline()
  //tween rabbit
  .fromTo("#cat-cheshire", {y: -100, x: 0, scale: 1, opacity:1}, {duration: 6, y: -100, x: -150, scale: 1.2, opacity:1})   
  .fromTo("#alice-cheshire", {y: -300, x: 100, scale: 1}, {y: -300, scale: 1.2, duration: 5}, '<')
  .fromTo("#balloon-01", {y: 0, x: 0, opacity: 0}, {y: 100, x: 0, opacity: 1, duration: 2}, '<')
      .to("#balloon-01", {delay: 2, opacity: 0, duration: 1}, '>')
  .fromTo("#balloon-02", {y: 0, x: 0, opacity: 0}, {y: 100, x: 0, opacity: 1, duration: 2}, '<')
      .to("#balloon-02", {opacity: 0, duration: 1}, '>')
  .fromTo("#balloon-03", {y: 0, x: 0, opacity: 0}, {y: 100, x: 0, opacity: 1, duration: 2}, '<')
      .to("#balloon-03", {opacity: 0, duration: 1}, '>')
      .to("#alice-cheshire", {opacity: 0, duration: 1}, '<')
      .to("#cat-cheshire .cat", {opacity: 0, duration: 2}, '<')
      .to("#cat-cheshire .smile", {rotate: 10, scale: 1.5, duration: 1}, '>') 
  .fromTo("#balloon-04", {y: 0, x: 0, opacity: 0}, {y: 50, x: 0, opacity: 1, duration: 2}, '<')
      .to("#balloon-04", {opacity: 0, delay: 4, duration: 1}, '>')
      .to("#cat-cheshire .smile", {opacity: 0, duration: 3}, '<') 
    
  var sceneCat = new ScrollMagic.Scene({
  triggerElement: '#section-vignetta', 
  duration: '120%',
  triggerHook: 0.25
  })
  .setTween(tlCat)
  .addTo(controller);

});



//////////////////////////////
// aanimate logo
/////////////////////////////

$('#section-end').each(function() { 

var tlIntroLogo = new Timeline()
  .from("#section-end .logo-a1", {opacity:0, x:10},)
  .from("#section-end .logo-a2", {opacity:0, x:-10}, '<')
  .from("#section-end .logo-l", {opacity:0, x:10}, '>')
  .from("#section-end .logo-i2", {opacity:0, x:-10}, '<')
  .from("#section-end .logo-i1", {opacity:0, x:10}, '>')
  .from("#section-end .logo-c", {opacity:0, x:-10}, '<')
  .to("#section-end #logo-alicia", {scale:.9, y:-20})
  .from("#section-end #logo-line", {opacity:0})
  .from("#section-end #tagline", {duration:1, opacity:0, y:20})
  .from("#section-end .btn", {opacity:0, x:-10})
  //.to("#homeBkgd", {opacity: 0.7, duration: 1})
  //.from("#footer span", {duration:1, opacity:0, y:12});

  var IntroLogo = new ScrollMagic.Scene({
    triggerElement: '#section-end',
    triggerHook: "0.5",
    //duration: '50'
    reverse: false
    })
    .setTween(tlIntroLogo)
    .addIndicators({
      name: 'SECTION END',
      colorTrigger: 'red',
      colorStart: 'red',
      colorEnd: 'rer'
    })
    .addTo(controller);

  });

//////////////////////////////
// Audio
/////////////////////////////



